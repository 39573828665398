<template>
  <div class="childContainer mediaLeft">
    <div class="mediaContainer">
      <img class="media" src="../../assets/customMade/custom1.jpg" alt="" />
    </div>
    <div class="textContainer">
      <div class="content">
        <h2>Le Rendez-vous :</h2>
        <p>
          Lors d'un échange téléphonique ou autour d'un café à Lyon, nous
          discuterons de vos attentes et vos souhaits.
        </p>
        <p>
          Je mettrai tout mon savoir-faire à votre service, nous évoquerons
          ensemble les formes de votre futur bijou, des pierres qui viendront
          l'orner, et bien sûr toute la symbolique qui gravite autour.
        </p>
        <p>
          Si c'est un bijou à offrir, j'aime m'appuyer sur l'histoire et la
          relation entre les personnes pour créer une pièce unique. Un cadeau à
          garder toute une vie et gage de vos plus beaux souvenirs.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>