<template>
  <div class="childContainer mediaRight">
    <div class="mediaContainer">
      <img class="media" loading="lazy" src="../../assets/customMade/custom2.jpg" alt="" />
    </div>
    <div class="textContainer">
      <div class="content">
        <h2>La Réalisation :</h2>
        <p>
          Suite à nos échanges, je vous proposerai plusieurs dessins qui seront
          modifiables et ajustables jusqu'à ce que vous soyez complètement
          satisfait.<br />Une fois réalisée, une vue 3D de votre bijou vous sera
          envoyée pour valider les courbes et volumes.
        </p>
        <p>
          La fabrication de votre bijou est comprise dans un délai de 3 à 6
          semaines. Pendant cette période, si vous le souhaitez, vous recevrez
          régulièrement des photos de l'avancée à l'atelier.
        </p>
        <p>
          Au terme de ce délai, vous recevrez votre bijou rêvé dans un écrin,
          prêt à vivre de belles aventures et à graver de nouveaux souvenirs.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>